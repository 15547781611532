body {
    background-color: #564dd0;
}

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background-image: url("./backDesktop.png");
    background-size: cover;
    background-color: #564dd0;
    background-position-y: 15%;
}
/* iframe {
    pointer-events: none;
} */
.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin:20px 50px;
}

.btn {
    width: 100%;
    height: 65px;
    margin-top: 29px;
    margin-bottom: 25px;
    border: none;
    border-radius: 10px;
    background-color: #55d27c;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.btn:hover {
    box-shadow: 0 0 16px 0 rgba(27, 183, 97, 0.6), 0 0 16px 0 rgba(27, 183, 97, 0.57);
}

.btn:active {
    box-shadow: none;
    background-color: #44a863;
}
.WidgetContaier{
    margin: 45px 0;
    position: relative;
}
.WidgetPage{
    display: flex;
    justify-content: center;
    align-items: center;

}
.WidgateBack{
    position: absolute;
    top: 0;
    left: 35px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
@media screen and (max-width: 480px) {
    body {
    }
    .container {
        margin: 10px;
    }
    .App {
        background-image: url("./backMobile.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 235px;
    }
    .WidgateBack{
        left: 20px;
    }
}

@media only screen and (min-width: 1585px) {
    .App {
        /* height: 100vh; */
    }
}


